import { find, includes, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../../DropdownSelect';
import { useCatalog, useStakeholders } from '../../../../hooks';

const ItemGroupedDropdown = ({ setFilter, value, isRecycler = false, ...props }) => {
  const {
    itemsFlat,
    materialTypesFlat,
    categoriesFlat,
    materialTypes,
    activeMaterialTypes,
    activeCategories,
    activeItems,
  } = useCatalog();

  const { currentAccount } = useStakeholders();
  const splitAccountCategories = currentAccount?.category?.split(',');

  const [materialOptions, setMaterialOptions] = useState([...materialTypesFlat]);
  const [itemsOptions, setItemsOptions] = useState([...activeItems]);
  const [categoryOptions, setCategoryOptions] = useState([...categoriesFlat]);

  const includedAll = includes(splitAccountCategories, 'All');

  const getFilteredData = useCallback(() => {
    if (!includedAll) {
      const getMaterial = activeMaterialTypes?.filter(({ name }) =>
        splitAccountCategories.includes(name)
      );
      const getCat = activeCategories?.filter(({ materialTypeId }) =>
        getMaterial?.find(({ id }) => id === materialTypeId)
      );
      const getItems = activeItems?.filter(({ categoryId }) =>
        getCat?.find(({ id }) => id === categoryId)
      );
      return { getMaterial, getCat, getItems };
    }
    return {};
  }, [activeMaterialTypes, activeCategories, activeItems, includedAll]);

  const accountBasedItemsFlat = includedAll ? activeItems : getFilteredData().getItems;

  const baseCatData = includedAll ? activeCategories : getFilteredData().getCat;

  useEffect(() => {
    if (!includedAll) {
      const newKeyInMaterialTypes = getFilteredData()?.getMaterial?.map((option) => {
        return { ...option, materialKey: 'materialValue' };
      });
      const newKeyInCategories = getFilteredData().getCat?.map((option) => {
        return { ...option, categoryKey: 'categoryValue' };
      });
      const accountBasedMaterialTypes = newKeyInMaterialTypes;
      setMaterialOptions(accountBasedMaterialTypes);

      // const itemIds = accountBasedItemsFlat?.map((option) => option?.categoryDTO?.id);
      const accountBasedCategories = newKeyInCategories;
      setCategoryOptions(accountBasedCategories);
      setItemsOptions(accountBasedItemsFlat);
    } else {
      const newKeyInMaterialTypes = activeMaterialTypes?.map((option) => {
        return { ...option, materialKey: 'materialValue' };
      });
      const newKeyInCategories = activeCategories?.map((option) => {
        return { ...option, categoryKey: 'categoryValue' };
      });
      setMaterialOptions(newKeyInMaterialTypes);
      setCategoryOptions(newKeyInCategories);
    }
  }, []);

  const onChangeHandler = (event) => {
    setItemsOptions([]);
    let filterItemsIds = [];

    event?.map((option) => {
      const keys = Object.keys(option);
      // if it is material
      if (keys?.includes('materialKey')) {
        const materialItems = accountBasedItemsFlat?.reduce((acc, itemOption) => {
          const catId = activeCategories?.find(({ id }) => id === itemOption?.categoryId);
          const materialData = activeMaterialTypes?.find(({ id }) => id === catId?.materialTypeId);
          if (materialData?.id === option?.id) {
            acc?.push(itemOption?.id);
          }
          return acc;
        }, []);
        filterItemsIds = [...filterItemsIds, materialItems];
      }

      // if it is category
      else if (keys?.includes('categoryKey')) {
        const categoryItems = accountBasedItemsFlat?.reduce((acc, itemOption) => {
          if (itemOption?.categoryId === option?.id) {
            acc?.push(itemOption?.id);
          }
          return acc;
        }, []);
        filterItemsIds = [...filterItemsIds, categoryItems];
      }
      // if it is items
      else {
        const mappedItemsIds = event?.map((selectedEventItem) => {
          return selectedEventItem?.id;
        });
        filterItemsIds = [...filterItemsIds, mappedItemsIds];
      }
      return filterItemsIds;
    });
    const singleArrayFilterIds = []?.concat(...filterItemsIds);

    const filterItemsIdsObject = accountBasedItemsFlat?.filter((fItems) => {
      return !singleArrayFilterIds?.includes(fItems?.id);
    });

    const getItemData = accountBasedItemsFlat?.filter(({ id }) =>
      singleArrayFilterIds.includes(id)
    );

    const catId = getItemData.map((cat) => cat?.categoryId);

    const getRestCat = baseCatData.filter((category) => {
      return !catId.includes(category.id);
    });
    // const itemsCategoryIds = filterItemsIdsObject?.map((option) => option?.categoryDTO?.id);
    // const filterCategoryIdsObject = categoriesFlat?.filter((cItems) => {
    //   return itemsCategoryIds?.includes(cItems?.id);
    // });
    const _filterCategoryIdsObject = getRestCat?.map((option) => {
      return { ...option, categoryKey: 'categoryValue' };
    });
    setCategoryOptions(_filterCategoryIdsObject);
    setItemsOptions((prev) => [...prev, ...filterItemsIdsObject]);
    // if the selected material or category has no items
    if (isEmpty(singleArrayFilterIds) && !isEmpty(event)) {
      setFilter('null');
    }
    // if it is recycler module
    else if (isRecycler) {
      setFilter(filterItemsIds.join(','));
    }
    // if we clear the selected one
    else if (isEmpty(event)) {
      setFilter(undefined);
    } else {
      setFilter(filterItemsIds);
    }
  };

  const groupedOptions = [
    {
      label: (
        <p className="font-bold" style={{ color: '#34948E' }}>
          Materials
        </p>
      ),
      options: materialOptions,
    },
    {
      label: (
        <p className="font-bold" style={{ color: '#34948E' }}>
          Categories
        </p>
      ),
      options: categoryOptions,
    },
    {
      label: (
        <p className="font-bold" style={{ color: '#34948E' }}>
          Items
        </p>
      ),
      options: itemsOptions,
    },
  ];

  return (
    <DropdownSelect
      isMulti
      options={groupedOptions}
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      onChange={onChangeHandler}
      {...props}
    />
  );
};

ItemGroupedDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default ItemGroupedDropdown;

import { createSlice } from '@reduxjs/toolkit';
import { keyBy, groupBy } from 'lodash';

const initialState = {
  items: null,
  categories: null,
  qualityParams: null,
  materialTypes: null,
  activeSellerTypes: null,
  materials: null,
  newItems: null,
};

function getCategories(data) {
  return keyBy(
    data.map((item) => item.category),
    'id'
  );
}

function setMaterialTyoeData(data) {
  data?.forEach((type, idx) => {
    const { categories } = type;
    data[idx].options = categories?.map((cat) => ({
      ...cat,
      matTypeId: type?.id,
      options: cat?.items?.map((itemVal) => ({ ...itemVal, matTypeId: type?.id, catId: cat?.id })),
    }));
  });
  return data;
}

const CatalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalog: (state, action) => {
      // state.items = keyBy(action.payload, 'id');
      // state.categories = getCategories(action.payload);
    },
    setQualityParam: (state, action) => {
      state.qualityParams = groupBy(action.payload, 'itemId');
    },
    setMaterialTypeList: (state, action) => {
      // state.materialTypes = setMaterialTyoeData(action.payload);
    },
    setActiveSellerTypes: (state, action) => {
      state.activeSellerTypes = action.payload;
    },
    setMaterials: (state, action) => {
      state.materialTypes = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const catalogActions = CatalogSlice.actions;
export default CatalogSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { filter, includes } from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { useCatalog, useStakeholders } from '../../../hooks';

const allCategoriesArr = { id: 'All', name: 'All Categories' };

const CategoryDropdown = ({ MaterialTypeId, aLLCategoriesRequired = false, ...props }) => {
  const {
    itemsFlat,
    categoriesFlat,
    categories,
    activeCategories,
    activeMaterialTypes,
  } = useCatalog();
  const { currentAccount } = useStakeholders();

  const splitCategory = currentAccount?.category?.split(',');
  const materialNames = activeMaterialTypes?.find((materialTypes) =>
    splitCategory?.includes(materialTypes?.name)
  );
  let filteredCategories = [];
  if (MaterialTypeId) {
    filteredCategories = categories?.filter(
      (category) => category?.materialTypeId === MaterialTypeId
    );
    filteredCategories = !includes(splitCategory, 'All')
      ? activeCategories?.filter((option) => splitCategory?.includes(option?.name))
      : categoriesFlat;
  } else {
    // eslint-disable-next-line no-nested-ternary
    filteredCategories = !includes(splitCategory, 'All')
      ? materialNames
        ? activeCategories?.filter((option) => option?.materialTypeId === materialNames?.id)
        : activeCategories?.filter((option) => splitCategory?.includes(option?.name))
      : categoriesFlat;

    filteredCategories = _.uniqBy(filteredCategories, (e) => e?.id);
    if (aLLCategoriesRequired) {
      filteredCategories?.unshift(allCategoriesArr);
    }
  }
  // let filterCategories =
  //   MaterialTypeId !== undefined
  //     ? itemsFlat
  //         ?.filter((item) => item?.categoryDTO?.materialType?.id === MaterialTypeId)
  //         .map((item) => item.categoryDTO)
  //     : categoriesFlat;

  // filterCategories = !includes(splitCategory, 'All')
  //   ? itemsFlat
  //       .filter((option) => splitCategory.includes(option?.categoryDTO?.materialType?.name))
  //       .map((option) => option?.categoryDTO)
  //   : categoriesFlat;

  // filterCategories = _.uniqBy(filterCategories, (e) => e.id);

  // if (aLLCategoriesRequired) {
  //   filterCategories.unshift(allCategoriesArr);
  // }

  return <DropdownSelect options={filteredCategories} {...props} />;
};

CategoryDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CategoryDropdown;

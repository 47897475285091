import { useSelector } from 'react-redux';
import { flatMap, keyBy, groupBy } from 'lodash';

export default function useCatalog() {
  const { items = {}, categories, qualityParams, materialTypes, activeSellerTypes } = useSelector(
    ({ catalog }) => catalog
  );

  const flatMapItems = items;

  const materialNameGroupedItems = groupBy(flatMapItems, (option) => {
    return option?.categoryDTO?.materialType?.id;
  });

  const activeMaterialTypes = materialTypes
    ?.filter((item) => item?.active)
    ?.sort((m1, m2) => m1.materialRank - m2.materialRank);

  const activeItems = items?.filter((item) => item?.active);
  const activeCategories = categories
    ?.filter((item) => item?.active)
    ?.sort((c1, c2) => c1.categoryRank - c2.categoryRank);

  return {
    items,
    categories,
    qualityParams,
    itemsFlat: flatMapItems,
    categoriesFlat: categories,
    qualityParamsFlat: flatMap(qualityParams),
    materialTypes,
    materialTypesList: keyBy(materialTypes, 'id'),
    materialTypesFlat: flatMap(materialTypes),
    categoryItems: groupBy(items, 'categoryId'),
    materialNameGroupedItems,
    activeSellerTypes,
    activeMaterialTypes,
    activeItems,
    activeCategories,
  };
}
